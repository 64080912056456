import * as React from 'react';

import IndexComponent from '../component/index';

// markup
const IndexPage = () => {
  return (
    <IndexComponent/>
  );
};


export default IndexPage;
