import * as React from 'react';

import profilePic from '../images/profile-pic.jpg';

import '../styles/main.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const links = [
  {
    name: 'Twitter',
    url: 'https://twitter.com/renzo_ui',
    icon: faTwitter,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/renzogaspary/',
    icon: faLinkedin,
  },
  {
    name: 'Github',
    url: 'https://github.com/rgaspary',
    icon: faGithub,
  },
];

export default class IndexComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRainbow: false,
    };

    this.toggleRainbow = this.toggleRainbow.bind(this);
  }

  currentYear() {
    return new Date().getFullYear();
  }

  toggleRainbow() {
    const currentState = this.state.isRainbow;
    this.setState({ isRainbow: !currentState });
  }

  render() {
    return (
      <main className={this.state.isRainbow ? 'bg-gradient rainbow' : 'bg-gradient'} ontouchstart="">
        <section className="content">
          <div className="content__profile-picture" role="button" tabIndex="0" onClick={this.toggleRainbow} onKeyPress={this.toggleRainbow}>
            <div className="content__profile-picture__container">
              <div className="content__profile-picture__front">
                <img src={profilePic} alt="my profile"/>
              </div>
              <div className="content__profile-picture__back">
                <p>I like colors<br/>Do you want to see some?</p>
              </div>
            </div>
          </div>
          <h1 className="content__title">Renzo Gaspary</h1>
          <h2 className="content__sub-title">Senior UI Developer</h2>
          <div className="content__social-links">
            {links.map(link => {
              return (
                <a
                  className="content__social-links__social-link"
                  href={link.url}
                  rel="noreferrer"
                  target="_blank"
                  alt={link.name}
                  key={link.name}
                >
                  <FontAwesomeIcon icon={link.icon}/>
                  <span className="hover-text">{link.name}</span>
                </a>
              );
            })}
          </div>
        </section>
        <footer className="footer">Copyright &copy; <span id="footerYear">{this.currentYear()}</span> - Renzo Gaspary</footer>
      </main>
    )
  }
}